import React from "react"
import styled, { ThemeProvider } from "styled-components"
import GlobalStyles from "../../styles/global"
import { defaultTheme } from "../../styles/theme"
import { customMedia } from "../../styles/customMedia"
import { HeroContentContactReceived } from "../../components"

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  border-radius: 0px 24px 0px 0px;
  background: white;

  ${customMedia.greaterThan("mediumDesktop")`
    border-radius: 0;
  `}
`

export const BackgroundContainer = styled.div`
  background-size: 100%;
  background-position-y: 450px;

  ${customMedia.greaterThan("mediumDesktop")`
    background-position-y: 250px;
  `}

  ${customMedia.greaterThan("hugeDesktop")`
    background-position-y: 150px;
  `}
`

export const AwardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;

  ${customMedia.greaterThan("mediumDesktop")`
    align-items: stretch;
    margin-top: 100px;
    padding: 0 15%;
  `}
`

function ContatoRecebido() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <MainContainer>
        <BackgroundContainer>
          <HeroContentContactReceived />
        </BackgroundContainer>
      </MainContainer>
    </ThemeProvider>
  )
}

export default ContatoRecebido
